import React from "react";

import { Layout } from "../components/globals/Layout";
import { SEO } from "../components/globals/SEO";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>Not Found</h1>
  </Layout>
);

export default NotFoundPage;
